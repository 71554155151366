import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export default () => (
  <div>
    <div className="row">
      <h1 className="mt-3 mb-1 px-1 py-1 bg-light-grey border">Where to Buy</h1>
    </div>
    <div className="row">
      <p className="mb-3 px-1">
        Panls whiteboards are available exclusively through our network of
        authorized distributors. We want you to have the best possible customer
        service and attention to detail, so we make sure all our distributors go
        through a strict approval process in order to carry our products. Buy
        with confidence only from Panls authorized distributors!
      </p>
      <p className="mb-3 px-1">
        <AniLink
          paintDrip
          hex="#ea4c12"
          duration={0.75}
          to="/buy/"
          className="btn-standard my-2"
        >
          How to Buy →
        </AniLink>
      </p>
    </div>
  </div>
)
