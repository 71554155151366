import React from "react"
import { Link } from "gatsby"
import Squeeze from "../layouts/squeeze-landing"
import Hero from "../components/hero"
import Base from "../layouts/base"
import SEO from "../components/SEO"
import Specs from "../components/specs"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import WhereToBuy from "../components/whereToBuy"
import TextLeftImgRight from "../components/textLeftImgRight"
import ImgLeftTextRight from "../components/imgLeftTextRight"

export default () => (
  <div>
    <SEO
      title="Rapid CeramicSteel Whiteboard | Magnetic Dry Erase Board"
      description="This is the description"
    />
    <Base>
      <Hero
        name="Rapid"
        headline="Frameless Concealed Mounting"
        text="Made from our rugged CeramicSteel, Rapid is the toughest whiteboard on the market.
							Simple to install with totally concealed mounts and easy to clean with almost any cleaner, Rapid
							also comes standard with our Lifetime Warranty."
        image="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/rapid-hero-2.jpg"
        color="rgb(255, 190, 25)"
      />
      <Squeeze>
        <TextLeftImgRight
          heading="Stress-Free Installation"
          p1="Rapid is very lightweight, making for easy installation. Our fully concealed mounting system, a mounting
					template, and comprehensive instructions are included with each Rapid
					whiteboard."
          p2="With this system, all mounting hardware is
					hidden behind the whiteboard and totally concealed from view.
					Installation can be completed by two people using basic tools in only
					three steps. As your needs change, this mounting system also lets you easily remove panels and
					reconfigure them."
          img="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/rapid-install-template.jpg"
          buybtn="d-none"
        />
        <ImgLeftTextRight
          heading="No-Hassle Cleaning"
          p1="Thanks to CeramicSteel, you can write on Panls whiteboards with dry-erase, semi-permanent, water-soluble or permanent markers without damaging the writing surface. That’s right – even permanent markers won’t damage Rapid."
          p2="To remove markings, you can use glass cleaners, detergent solutions, scrubbing brushes, abrasive cleaners, and even acid-based solutions. Nylon scouring pads, steel wool or solvent-based materials like acetone, alcohol, or paint remover can also be safely used on Panls dry erase boards."
          img="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/rapid-erase.jpg"
        />
        <TextLeftImgRight
          heading="Durable and Functional"
          p1="The core of Rapid’s clean aesthetic is its CeramicSteel dry erase surface
					material, miles ahead of melamine and painted
					steel with its impressive durability. Even glass dry erase boards can’t hold a candle to the toughness of
					our whiteboards. "
          p2="In fact, you could literally hold a candle to any Panls
					dry erase board with zero damage because our boards are fire
					resistant! Rapid also resists scratches, chemicals, bacteria, graffiti and stains.
					Don’t stress about shipping – the CeramicSteel
					is tough enough to resist impacts too. You can't say that about a glass
					dry erase board."
          img="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/limitless-durability-gif.gif"
          buybtn="d-none"
        />
        <div className="row mb-4 py-2 px-1 bg-light-grey border text-center">
          <img
            src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/magnet.png"
            className="mb-1"
          />
          <h2 className="mb-1">Fully Magnetic</h2>
          <p className="mx-3">
            All Panls CeramicSteel dry erase boards are all fully magnetic by
            nature right out of the box, meaning you can keep using your
            favorite magnets on Rapid! No additional backing materials or
            special magnets are required.
          </p>
        </div>
        <div className="row mb-4 text-center">
          <h2 className="mb-1">Find the Perfect Fit for Your Space</h2>
          <p>The Panls Rapid is available in two sizes 4'x4' and 4'x8'.</p>
          <img
            src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/rapid-size-guide.png"
            className="img-fluid"
          />
        </div>
        <div className="row my-2">
          <img
            src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/rapid-chart-contrast.jpg"
            className="img-fluid"
          />
        </div>
        <TextLeftImgRight
          heading="Clear Contrast & Detail"
          p1="The Rapid dry erase board comes standard in a
					bright, neutral white for excellent ink to surface contrast.
					This surface provides clear contrast in any
					lighting and even resists glare so you can clearly see notes from anywhere in the room."
          p2="Glass dry erase surfaces can't compete. Without a solid
					background to write on, notes on a glassboard will appear fuzzy 
					from just a short distance due to 
					a subtle “drop shadow” effect that distracts from your written
					content."
          img="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/rapid-art-installation.jpg"
        />
        <ImgLeftTextRight
          heading="Frameless Design"
          p1="Every Rapid dry erase board is totally frameless and cut as a single piece of rugged CeramicSteel. The frameless design means Rapid can seamlessly blend with any room. More than just a whiteboard, Rapid is a functional design element for your office or meeting space."
          img="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/rapid-frameless-slim.jpg"
          buybtn="d-none"
        />
        <WhereToBuy />
        <div className="row mb-4 mt-2 py-2 px-1 bg-light-grey border text-center">
          <img
            src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/ribbon-icon.png"
            className="mb-1"
          />
          <h2 className="mb-1">Panls Forever Warranty</h2>
          <p className="mx-3">
            Every Panls dry erase board comes with our Lifetime Warranty for as
            long as you own the board. The CeramicSteel dry erase surfaces are
            designed to last at least 50 years, but your warranty won’t end
            there – it won’t even end if your board lasts more than 150 years!
            We’ll support your product no matter how long ago you bought it.
          </p>
        </div>
        <div className="bg-light-grey">
          <h1 className="mt-3 mb-1 px-1 py-1">Tech Specs</h1>
          <hr />
          <Specs
            attribute1="Available Sizes"
            spec1="4x4ft • 4x8ft"
            attribute2="Surface Type"
            spec2="CeramicSteel"
          />
          <Specs
            attribute1="Weight"
            spec1="23.23lbs • 46.78lbs"
            attribute2="Warranty"
            spec2="Lifetime"
          />
        </div>
        <div className="row my-3 px-1">
          <h1>CeramicSteel Whiteboard vs Glassboard</h1>
          <img
            src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/limitless-vs-glass.jpg"
            className="img-fluid my-2"
          />
        </div>
        <div className="row px-1 d-flex-center">
          <div className="six columns py-2">
            <h2 className="mb-2 div-heading">The Panls Advantage</h2>
            <p>
              These days, many schools and businesses are switching to glassboards
              because of their looks – but when it comes to whiteboards, it’s
              never just about appearances. A whiteboard also needs to be
              functional. The CeramicSteel dry erase surface is a wonder material
              because it combines all these elements. It features a smooth,
              non-porous surface which appears very glass-like but is much more
              durable. With our frameless design and totally hidden mounting
              hardware on Rapid, this dry erase board is just as much a design
              element for your space as any glassboard, yet it’s much more
              functional.
            </p>
          </div>
          <div className="six columns py-2">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/5vfoO7UvJSM" allowfullscreen></iframe>
            </div>
          </div>
        </div>
        <div className="row my-3 px-1">
          <ul>
            <li className="my-2">
              <strong>Crisp Contrast, High Visibility</strong> - The Panls
              CeramicSteel dry erase surface provides some of the best contrast
              on the market – that claim can’t be made for glassboards. With a
              glass whiteboard, because the material is transparent by nature,
              you don’t get that sharp distinction between written ink and dry
              erase surface. This can make your notes appear blurry or fuzzy at
              only a short distance. With CeramicSteel, you get a smooth, solid
              dry erase surface that contrasts well with any dry erase inks in
              any lighting.
            </li>
            <li className="my-2">
              <strong>Low Glare Surface</strong> - Another major drawback of
              glass is the reflectivity of the material. This might look good as
              a wall hanging, but definitely won’t look good when you’re trying
              to present clear notes to a large group - your writing will get
              completely lost in the glare. Glass actually has two reflective
              panes, front and back, so any reflections on the material are
              amplified even further. The CeramicSteel isn’t totally resistant
              to glare and reflection, but every one of our finish options is
              less reflective than a glass whiteboard. In fact, CeramicSteel is
              58.8% less reflective than glass according to an internal study.
            </li>
            <li className="my-2">
              <strong>Fully Magnetic</strong> - Glass is not a magnetic material
              by nature - in order to make a glassboard magnetic, you will need
              additional backing materials which can add weight and cost. Panls
              CeramicSteel dry erase surface is fully magnetic from edge to edge
              by nature, right out of the factory. There’s no additional
              materials, costs, or special setup to perform.
            </li>
            <li className="my-2">
              <strong>Shipping &amp; Handling</strong> - Glass is a fragile
              material. Even the tempered glass used in glass whiteboards is
              much more fragile than a CeramicSteel dry erase surface. That
              means that glassboards must be shipped standing vertically to
              prevent damage, which can add a significant cost. Panls
              CeramicSteel whiteboards like Rapid are highly resistant to impact
              damage and can be shipped in any orientation with no special
              handling required.
            </li>
            <li className="my-2">
              <strong>Straightforward Install</strong> - Installation can be a
              daunting process for a glassboard. Glassboards are much heavier
              than CeramicSteel whiteboards so you’ll need more people to get
              them set up, as well as a stronger mounting system which can be
              more difficult to install. You’re also much more likely to damage
              a glass whiteboard during installation because of the extra
              weight. Panls CeramicSteel whiteboards address all these issues –
              they are extremely lightweight and can be hung by just two people,
              and every board includes detailed mounting instructions. With
              Rapid, you even get a mounting template which shows you exactly
              where to drill.
            </li>
            <li className="my-2">
              <strong>Safety</strong> - At this point, it should become clear
              that glass whiteboards have major drawbacks when compared to Panls
              CeramicSteel whiteboards. Panls whiteboards are much more
              lightweight and durable than glassboards – even if you do drop a
              Panls whiteboard, it likely won’t even get a scratch. That cannot
              be said for glass whiteboards. Even a minor drop can cause a
              glassboard to crack or, at worst, shatter into thousands of tiny
              glass shards which are dangerous and tough to clean up.
            </li>
          </ul>
        </div>

        <div className="row">
          <img
            src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/polyvision_hedwig_easy_install_corporate_v04.jpg"
            className="img-fluid"
          />
        </div>

        <div className="row px-1">
          <h1 className="my-2">Rapid Whiteboard Applications</h1>
          <p>
            Rapid provides excellent value in a frameless, aesthetic package
            that's beautiful and highly durable thanks to the CeramicSteel dry
            erase surface. This surface is the flagship piece of all Panls
            whiteboards and is the main reason our boards are easy to clean,
            easy to install, and strong enough to last 50+ years. Rapid also
            includes detailed mounting instructions so you can get up and
            running fast and is backed by our standard Lifetime Warranty for as
            long as you own the whiteboard.
          </p>
        </div>

        <div className="row my-3">
          <div className="four columns">
            <ul>
              <li className="">
                <p className="bold">Interior Design</p>
              </li>
              <li className="">
                <p className="bold">Architecture</p>
              </li>
              <li className="">
                <p className="bold">Hospitality</p>
              </li>
              <li className="">
                <p className="bold">Oil and Gas</p>
              </li>
            </ul>
          </div>
          <div className="four columns">
            <ul>
              <li className="">
                <p className="bold">Technology</p>
              </li>
              <li className="">
                <p className="bold">Construction</p>
              </li>
              <li className="">
                <p className="bold">Healthcare</p>
              </li>
              <li className="">
                <p className="bold">Education</p>
              </li>
            </ul>
          </div>
          <div className="four columns">
            <ul>
              <li className="">
                <p className="bold">Sports</p>
              </li>
              <li className="">
                <p className="bold">Finance</p>
              </li>
              <li className="">
                <p className="bold">Military</p>
              </li>
              <li className="">
                <p className="bold">Government</p>
              </li>
            </ul>
          </div>
        </div>
        <WhereToBuy />
      </Squeeze>
    </Base>
  </div>
)
