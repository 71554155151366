import React from 'react'
import styles from './specs.module.css'

export default props => (
  <div className={`${styles.specsRow} row`}>
    <div className={`${styles.specsCol} six columns`}>
      <div className={`${styles.specsCat} text-center py-2 px-1`}>
        <h3>{props.attribute1}</h3>
        <p>{props.spec1}</p>
      </div>
    </div>
    <div className={`${styles.specsCol} six columns`}>
      <div className={`${styles.specsCat} text-center py-2 px-1`}>
        <h3>{props.attribute2}</h3>
        <p>{props.spec2}</p>
      </div>
    </div>
  </div>
)
